<template>
  <div class="line-view container">
    <div v-if="webId != null">

      <div v-if="kakaoUserId !=null">
        <KakaoList />
      </div>
      <div v-else>
        <kakao-login-button  @result="result"/>
      </div>

    </div>
    <div v-else class="text-center">
      <br />
      <SolidLoginButton />
    </div>
  </div>
</template>

<script>
// @ is an alias to /src
export default {
  name: "Kakao",
  components: {
    'SolidLoginButton': () => import('@/components/solid/SolidLoginButton'),
    'KakaoList': () => import('@/components/kakao/KakaoList'),
    'KakaoLoginButton': () => import('@/components/KakaoLoginButton'),
  },
  computed:{
    webId(){
      return this.$store.state.solid.webId
    },
    kakaoUserId(){
      return this.$store.state.kakao.kakaoUserId
    }
  },
  data() {
    return {
      //lineUserId: null
      // clientId: '',
      // clientSecret: '',
      // callbackUri: ''
    }
  },
  created: function () {
    // line login check
    if (localStorage.getItem("kakao_access_token") != null) {
      const token = localStorage.getItem("kakao_access_token")
      //console.log(token);
      this.$store.dispatch('kakao/getKakaoUser', token);
    }
  },
  methods: {
    result(res) {
      console.log("res:"+res)
    },
  }
}
</script>
<style>
.line-view {
  text-align: left;
  padding: 0;
}
</style>